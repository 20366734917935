<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
          <v-card class="elevation-12">
            <content-toolbar />
            <router-view></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ContentToolbar from "../components/contents/ContentToolbar.vue";

export default {
  name: "CotnentView",
  components: { ContentToolbar },
};
</script>